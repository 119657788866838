import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "@emotion/styled"

const AboutLeadText = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
`

const SectionStyled = styled.section`
  ${props => props.blue && `
    background: rgba(38,170,226,.2);
  `}
  .section-subhead {
    font-weight: 300;
    font-size: 3.3rem;
    @media (min-width: 630px) {
      font-size: 3.5rem;
    }
    @media (min-width: 768px) {
      font-size: 4rem;
    }
    @media (min-width: 1200px) {
      font-size: 5.5rem;
    }
    line-height: 1.7;
  }
  .section-text {
    font-size: 24px;
    line-height: 1.7;
    font-weight: 300;
    font-size: 1.5rem;
    @media (min-width: 630px) {
      font-size: 1.7rem;
    }
  
  }
`

const TextSection = ({ sectionClass, subheader, headerClass = "", text, ...props }) => (
  <SectionStyled className={sectionClass} {...props}>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-9">
          <h1 className={`section-subhead ${headerClass}`}>{subheader}</h1>
          <AboutLeadText className="font-weight-light h4 section-text">
            {text.split("######").map(x => (
              <p key={x}>{x}</p>
            ))}
          </AboutLeadText>
        </div>
      </div>
    </div>
  </SectionStyled>
)

export default ({ data }) => (
  <Layout
    styles=""
    description="We use technology and infastructure to tackle the unmet need of the our times: early childhood education. Learn more today."
    title="About"
  >
    <TextSection
      blue={true}
      sectionClass=""
      subheader="About Us"
      headerClass="text-primary"
      text="Founded in 2015, Tembo Education Group utilizes technology and infastructure to tackle the unmet need of the our times: early childhood education. We focus on children ages 0 to 6, an instrumental time that creates the building blocks of the rest of a person's life. The mission of Tembo Education Group is to deliver a global, scalable solution for two pressing problems of modern education: access and screen dependency."
    />
    <TextSection
      sectionClass="bg-primary text-white"
      headerClass=""
      subheader="Social Impact"
      text="We work to develop minds between the ages of 0 to 6
              across the developed and developing worlds through the accessible
              delivery of early childhood education (or ECE). Early childhood is a crucial time for the development of a child and a parent. 
             Harvard University research shows that 86-98% of a child’s vocabulary is derived from their parents. 
             Vocabulary growth corresponds with higher test scores, mental processing speed and language learning abilities.
             Additionally, studies have found that every dollar invested in ECE results in a
             return on investment of $7-$13. We want the
              hundreds of millions of children ages 0 to 6 in the developing world to
              reach their full cognitive potential."
    />

    <TextSection
      sectionClass="bg-light"
      headerClass="text-primary"
      subheader="Partnerships"
      text="We partner with NGOs, telecoms, and other companies to deliver our
              learning platform globally and reach markets where quality,
              curriculum-based early childhood learning is often financially and
              geographically inaccessible. In the developing world, we leverage
              these relationships to create incentives for learning, such as
              free data and airtime.######
              We have worked with several leading organizations on pilot studies
              with our text-based learning platform. Additionally, we offer
              bespoke learning solutions for older children and adult learning
              contexts. If you would like to learn more, please contact us."
    />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
